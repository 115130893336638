// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

// SEO Related settings
const seo = {
  title: "Fernando's Portfolio",
  description: "Hi there! I'm a Software Engineer with over a decade of experience building scalable and efficient backend solutions using Microsoft technologies, modern design patterns, best practices, and cloud computing. Welcome to my portfolio—let's explore my journey in tech!",
  og: {
    title: "Fernando Luiz de Lima Portfolio",
    type: "website",
    url: "https://fernandolima.tech",
  },
};

// Home Page
const greeting = {
  title: "Fernando Luiz de Lima",
  logoName: "FernandoLuizDeLima",
  nickname: "Lima",
  subTitle: "Hi there! I'm a Software Engineer with over a decade of experience building scalable and efficient backend solutions using Microsoft technologies, modern design patterns, best practices, and cloud computing. Welcome to my portfolio—let's explore my journey in tech!",
  resumeLink: "https://drive.google.com/file/d/1v4PczdUGrcadao3XSOwI2BakgJG9VocB/view?usp=sharing",
  portfolioRepository: "./",
  githubProfile: "https://github.com/ffernandolima",
};

const socialMediaLinks = [
  {
    name: "Gmail",
    link: "mailto:fernando.ftec@gmail.com",
    fontAwesomeIcon: "fab fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/fernando-luiz-lima/",
    fontAwesomeIcon: "fab fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Github",
    link: "https://github.com/ffernandolima",
    fontAwesomeIcon: "fab fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "X-Twitter",
    link: "https://x.com/lima_ffernando",
    fontAwesomeIcon: "fab fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Medium",
    link: "https://medium.com/@ffernandolima",
    fontAwesomeIcon: "fab fa-medium",
    backgroundColor: "#000000",
  },
  {
    name: "NuGet",
    link: "https://www.nuget.org/profiles/fernando.lima",
    fontAwesomeIcon: "fas fa-cube",
    backgroundColor: "#004880",
  },
];

const skills = {
  data: [
    {
      title: "Software Architecture & Development",
      fileName: "FullStackImg",
      skills: [
        "⚡Designing scalable and efficient backend architectures using .NET, cloud platforms, and microservices",
        "⚡Developing high-performance APIs and services using REST and gRPC",
        "⚡Implementing robust data storage solutions with relational and NoSQL databases, focusing on optimization and scalability",
        "⚡Ensuring fault tolerance and resilience through distributed systems and event-driven architecture",
        "⚡Integrating advanced observability practices with monitoring, logging, and distributed tracing to ensure system reliability and performance",
      ],
      softwareSkills: [
        {
          skillName: ".NET",
          fontAwesomeClassname: "devicon:dotnetcore",
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "devicon:csharp",
        },
        {
          skillName: "HTML",
          fontAwesomeClassname: "devicon:html5",
        },
        {
          skillName: "CSS",
          fontAwesomeClassname: "devicon:css3",
        },
        {
          skillName: "Javascript",
          fontAwesomeClassname: "devicon:javascript",
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "devicon:git",
        },
        {
          skillName: "Github",
          fontAwesomeClassname: "devicon:github",
        },
        {
          skillName: "Kafka",
          fontAwesomeClassname: "devicon:apachekafka",
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "devicon:redis",
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "devicon:postman",
        },
        {
          skillName: "VisualStudio",
          fontAwesomeClassname: "devicon:visualstudio",
        },
      ],
    },
    {
      title: "Cloud Architecture & Infrastructure",
      fileName: "CloudInfraImg",
      skills: [
        "⚡Designing cloud-native solutions using AWS, Azure, and container orchestration with Kubernetes and Docker",
        "⚡Implementing Infrastructure as Code (IaC) using tools like Terraform and CloudFormation",
        "⚡Building scalable and secure systems with load balancing, auto-scaling, and disaster recovery strategies",
        "⚡Optimizing CI/CD pipelines to automate deployment and improve development workflows",
        "⚡Enhancing system observability with logging, monitoring, and distributed tracing using tools like Prometheus, Grafana, ELK Stack and Datadog",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "devicon:azure",
        },
        {
          skillName: "SQL Server",
          fontAwesomeClassname: "devicon:microsoftsqlserver",
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "devicon:mysql",
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "devicon:postgresql",
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "devicon:mongodb",
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "devicon:docker",
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "devicon:kubernetes",
        },
        {
          skillName: "Grafana",
          fontAwesomeClassname: "devicon:grafana",
        },
        {
          skillName: "OpenTelemetry",
          fontAwesomeClassname: "devicon:opentelemetry",
        },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "devicon:terraform",
        },
      ],
    },
    {
      title: "Data Science, Big Data & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡Applying Big Data tools and machine learning techniques to analyze complex datasets",
        "⚡Creating insightful data visualizations with tools like Power BI, Tableau, and Apache Superset",
        "⚡Exploring AI concepts such as NLP, computer vision, and predictive modeling",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "devicon:tensorflow",
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "devicon:keras",
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "devicon:pytorch",
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "devicon:python",
        },
        {
          skillName: "Deep Learning",
          imageSrc: "deepLearning.png",
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/u/fernando_lima/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/profile/fernando_ftec",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "UNOPAR",
      subtitle: "Graduate Course in Artificial Intelligence and Machine Learning",
      logoPath: "unopar.png",
      altName: "UNOPAR",
      duration: "2025",
      descriptions: [
        " Focused on: ",
        "⚡Aligning scientific and technical knowledge with emerging technologies and their presence in society;",
        "⚡Developing specialized skills in the development of intelligent systems;",
        "⚡Applying market practices to solve complex problems with AI-driven solutions;",
        "⚡Mastering tools and methodologies for building intelligent and scalable computational systems;",
        "⚡Enhancing problem-solving abilities to tackle modern computational challenges.",
      ],
      websiteLink: "https://www.unopar.com.br/",
      evidence: {
        link: "",
        description: "Certificate"
      },
    },
    {
      title: "UNOPAR",
      subtitle: "Graduate Course in Software Architecture",
      logoPath: "unopar.png",
      altName: "UNOPAR",
      duration: "2024",
      descriptions: [
        " Focused on: ",
        "⚡Analyzing and architecting software systems;",
        "⚡Designing high-level and cloud architecture models;",
        "⚡Applying Design Patterns and Configuration Management in software architecture;",
        "⚡Understanding Web Services and Microservices;",
        "⚡Integrating applications with DevOps practices;",
        "⚡Exploring the architecture of mobile applications.",
      ],
      websiteLink: "https://www.unopar.com.br/",
      evidence: {
        link: "https://drive.google.com/file/d/15T1dyj2ic5pXJmVFBhNyN64dm8Ni80mi/view?usp=sharing",
        description: "Certificate"
      },
    },
    {
      title: "CEL",
      subtitle: "English Course - Grade: Advanced A (CEFR C1 Equivalency)",
      logoPath: "cel.png",
      altName: "CEL",
      duration: "2023",
      descriptions: [
        " An immersive language program at the College of English Language (CEL) in Downtown San Diego, California. Focused on:",
        "⚡Developing essential English language skills, including reading, writing, speaking, and listening;",
        "⚡Enhancing vocabulary, grammar, pronunciation, and cross-cultural competence.",
      ],
      websiteLink: "https://www.englishcollege.com/",
      evidence: {
        link: "https://drive.google.com/file/d/1S5mXe8fomezg9bySw435QbqO1QBYfuIj/view?usp=sharing",
        description: "Certificate"
      },
    },
    {
      title: "EXIMIA CO",
      subtitle: "Software Architecture Mentorship",
      logoPath: "eximiaCo.png",
      altName: "EXIMIA CO",
      duration: "2022",
      descriptions: [
        " A mentorship offered by one of Brazil's most renowned Software Architects. Engaged in discussions covering:",
        "⚡Domain-driven design;",
        "⚡Evolutionary architectures;",
        "⚡Scalability;",
        "⚡Microservices;",
        "⚡Other advanced software engineering topics.",
      ],
      websiteLink: "https://elemarjr.com/clube-de-estudos/mentoria-arquitetura-software/",
    },
    {
      title: "UNIRITTER",
      subtitle: "Graduate Course in Data Science & Big Data",
      logoPath: "uniritter.png",
      altName: "UNIRITTER",
      duration: "2019 - 2020",
      descriptions: [
        " Focused on: ",
        "⚡Developing skills in processing large volumes of digital information;",
        "⚡Conducting exploratory data analysis;",
        "⚡Making inferences and predictions;",
        "⚡Generating insights for various business sector;",
        "⚡Preparing for the creation of Big Data systems, from conception to implementation, within a cloud-based infrastructure;",
        "⚡Preparing for using Data Science techniques to generate competitive intelligence and findings.",
      ],
      websiteLink: "https://www.uniritter.edu.br/",
      evidence: {
        link: "https://drive.google.com/file/d/1HRRM9I5FonGcHuWKt_JAkv3byrZD1Tpd/view?usp=sharing",
        description: "Certificate"
      },
    },
    {
      title: "EFN",
      subtitle: "English Course - Grade: CEFR C1",
      logoPath: "efn.png",
      altName: "EFN",
      duration: "2017 - Present",
      descriptions: [
        " A personalized one-on-one instruction with a dedicated tutor. Focused on:",
        "⚡Vocabulary enrichment;",
        "⚡Mastery of phrasal verbs;",
        "⚡Enhanced expressiveness and overall language proficiency;",
        "⚡Development of quick thinking and skim-and-scan abilities;",
        "⚡Strengthened comprehension skills;",
        "⚡Strong emphasis on conversational fluency.",
      ],
      websiteLink: "https://www.linkedin.com/company/english-for-need/",
      evidence: {
        link: "https://drive.google.com/file/d/1TWDIsl6nWaD7FzVSuUKIlz4qkPt9cr3N/view?usp=sharing",
        description: "Certificate"
      },
    },
    {
      title: "UNILASALLE",
      subtitle: "Undergraduate Course in Technology in Internet Systems",
      logoPath: "unilasalle.png",
      altName: "UNILASALLE",
      duration: "2011 - 2016",
      descriptions: [
        " Focused on: ",
        "⚡Developing skills in software engineering, IT infrastructure, information security, and more for creating programs compatible with the needs of different types of companies;",
        "⚡Preparing for analyzing complex computing problems, managing data, implementing systems, programming, and so on.",
      ],
      websiteLink: "https://www.unilasalle.edu.br/canoas",
      evidence: {
        link: "https://drive.google.com/file/d/1NZb2qyYn00dvm2-fVBQJM0kLHZvHfEOE/view?usp=sharing",
        description: "Diploma"
      },
    },
    {
      title: "O ACADÊMICO",
      subtitle: "Technical Course in Computer Programming",
      logoPath: "oacademico.png",
      altName: "O ACADÊMICO",
      duration: "2009 - 2011",
      descriptions: [
        " Focused on: ",
        "⚡Project information organization;",
        "⚡Data structure selection and utilization;",
        "⚡A solid foundation in algorithm development;",
        "⚡Expertise in programming languages.",
      ],
      websiteLink: "https://oacademico.com.br/",
      evidence: {
        link: "https://drive.google.com/file/d/1HXutGxNwXUQawd4KpN1ouZ6BllSJdiPF/view?usp=sharing",
        description: "Diploma"
      },
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "EF SET English Certificate (C2 Proficient)",
      subtitle: "- EF SET",
      logoPath: "efset.png",
      certificateLink: "https://cert.efset.org/2cUeLL",
      altName: "EF SET",
    },
    {
      title: "Foundational C# with Microsoft",
      subtitle: "- Quincy Larson & Julia Liuson",
      logoPath: "freeCodeCamp&microsoft.png",
      certificateLink: "https://www.freecodecamp.org/certification/fernandoluizdelima/foundational-c-sharp-with-microsoft",
      altName: "Microsoft",
    },
    {
      title: "Microsoft Certified Solutions Associate: Web Applications (MCSA)",
      subtitle: "- Satya Nadella",
      logoPath: "microsoft.png",
      certificateLink: "https://drive.google.com/file/d/1haRbhbKcwS9qgDb_Hmz8QcSDoyTf0sYc/view?usp=sharing",
      altName: "Microsoft",
    },
    {
      title: "Microsoft Certified Solutions Developer: App Builder Charter Member (MCSD)",
      subtitle: "- Satya Nadella",
      logoPath: "microsoft.png",
      certificateLink: "https://drive.google.com/file/d/1oP93edh56NoI0j5Jxm3EAKtmJLa8osVA/view?usp=sharing",
      altName: "Microsoft",
    },
    {
      title: "Microsoft Certified Solutions Developer: Web Applications (MCSD)",
      subtitle: "- Satya Nadella",
      logoPath: "microsoft.png",
      certificateLink: "https://drive.google.com/file/d/1uklGWi8hBWnKT3EUhVDMzT8Ni55z9boF/view?usp=sharing",
      altName: "Microsoft",
    },
    {
      title: "Microsoft Specialist: Programming in HTML5 with JavaScript and CSS3 (MS)",
      subtitle: "- Satya Nadella",
      logoPath: "microsoft.png",
      certificateLink: "https://drive.google.com/file/d/1xSLCXiplSSY66LRixCFjPYoUUAlpEw4-/view?usp=sharing",
      altName: "Microsoft",
    },
    {
      title: "Microsoft Technology Associate: Software Development Fundamentals (MTA)",
      subtitle: "- Satya Nadella",
      logoPath: "microsoft.png",
      certificateLink: "https://drive.google.com/file/d/1Iy6RTwsXdULawRzIUbQVXjPQ1CfrHqNd/view?usp=sharing",
      altName: "Microsoft",
    },
    {
      title: "Microsoft Certified Professional",
      subtitle: "- Satya Nadella",
      logoPath: "microsoft.png",
      certificateLink: "https://drive.google.com/file/d/1nIXVMiC8xvMUcRrs_5c-_BhOzvw1dBTG/view?usp=sharing",
      altName: "Microsoft",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Professional Background",
  description: "I have worked with startups and established companies as a Software Engineer, Architect, and Consultant, focusing on .NET, cloud architecture, microservices, and distributed systems. I lead teams to optimize performance, scalability, and security. In addition to my technical roles, I contribute to open-source projects and engage with the tech community through mentoring and collaboration.",
  headerImagePath: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Senior Software Engineer",
          company: "Mindbody",
          companyUrl: "https://www.mindbodyonline.com/",
          logoPath: "mindbody.png",
          duration: "Oct 2024 - Present",
          location: "San Luis Obispo, CA, US · Remote",
          description:
            "As a Senior Software Engineer, I play a pivotal role in the software architecture and development of the Core Appointments Squad, which focuses on modernizing legacy applications while adding new features to the platform. The appointment features are central to Mindbody's core business, enabling customers to schedule appointments for fitness, wellness, and beauty services. I work with a diverse, remote team from the US and Brazil to drive innovation and enhance our platform's capabilities. Main technologies: .NET (Core, 6 +), ASP.NET Core, Dapper, New Relic, Docker, Kubernetes, xUnit, Unit Tests, Integration Tests, Feature Toggle, AWS, NuGet, Git, Azure DevOps, Copilot, and others.",
          color: "#000000",
        },
        {
          title: "Senior Software Engineer",
          company: "Blankfactor",
          companyUrl: "https://blankfactor.com/",
          logoPath: "blankfactor.png",
          duration: "Jun 2024 - Oct 2024",
          location: "Florida, FL, US · Remote",
          description:
            "As part of a strategic project to re-engineer FIS Global's retirement platform, the work focused on modernizing and enhancing the system to improve scalability, performance, and security. The project involved re-architecting the platform to support complex financial processes and ensuring seamless integration with APIs, databases, and cloud infrastructure. The platform streamlined retirement account management, automated workflows, and enhanced user experience. As a Senior Software Engineer, with a particular focus on backend development, I've played a key role in designing and implementing core functionalities. My responsibilities included creating scalable architecture, optimizing performance, and ensuring high code quality. I collaborated with cross-functional teams, troubleshot and debugged backend services, and ensured secure and efficient data handling through APIs and cloud services.",
          color: "#000000",
        },
        {
          title: "Staff Software Engineer",
          company: "Neon",
          companyUrl: "https://neon.com.br/",
          logoPath: "neon.png",
          duration: "Feb 2024 - Jun 2024",
          location: "São Paulo, SP, BR · Remote",
          description:
            "I've played a significant role in the Cards Business Unit as a leader of two key projects. I was responsible for re-engineering the card generation and distribution process, ensuring that new debit/credit cards were generated, embossed, and shipped to customers efficiently. This included managing the re-issuance of expiring cards and keeping customers informed throughout the entire process. Additionally, I drove a gamification initiative designed to enhance customer engagement and encourage more frequent use of debit/credit cards. This initiative involved creating a journey with various challenges, rewarding customers with benefits like credit limit increases and invoice discounts upon completion. I worked with a wide range of technologies, including .NET (Core, 5 +), ASP.NET Core, EF Core, Dapper, Dynatrace, ELK Stack, Prometheus, Grafana, Kafka, RabbitMQ, Docker, Kubernetes, xUnit, Unit Tests, Integration Tests, DDD, CQRS, Feature Toggle, AWS, NuGet, Git, GitHub Actions, Copilot, among others.",
          color: "#000000",
        },
        {
          title: "Partner",
          company: "Warren Investimentos",
          companyUrl: "https://warren.com.br/",
          logoPath: "warren.png",
          duration: "Feb 2020 - Present",
          location: "Porto Alegre, RS, BR · Hybrid",
          description:
            "Recognized for my expertise and significant contributions to the company's success, I was elevated to a partner role, reflecting my impact on driving strategic initiatives, mentoring teams, and delivering scalable solutions that aligned with the organization's long-term goals.",
          color: "#000000",
        },
        {
          title: "Staff Software Engineer",
          company: "Warren Investimentos",
          companyUrl: "https://warren.com.br/",
          logoPath: "warren.png",
          duration: "Mar 2023 - Dec 2023",
          location: "Porto Alegre, RS, BR · Hybrid",
          description:
            "I played a pivotal role in the company's transformation journey. I led the Platform Squad in the Foundation Tribe. The squad's responsibilities included creating and maintaining packages, templates, and tools, offering technical guidance, promoting clean code practices, experimenting with new technologies, and assisting product teams in developing services as they transitioned from a monolith to microservices. My expertise included .NET (Framework, Core, 5 +), C#/ASP.NET Core, HangFire, Background Services, EF Core, Dapper, Redis, Jaeger/Datadog, ELK Stack, Prometheus, Grafana, Kafka, Docker, xUnit, Unit Tests, Integration Tests, Microservices, DDD, CQRS/Event Sourcing, Feature Toggle, AWS (S3, EC2, ECS, ELB, Route53, RDS, DocumentDB, CloudWatch, and more), Terraform, Traefik, NuGet, Git, TeamCity/Octopus, GitHub Actions, GitHub Packages, Visual Studio 2022, Visual Studio Code, and more.",
          color: "#000000",
        },
        {
          title: "Specialist Software Engineer",
          company: "Warren Investimentos",
          companyUrl: "https://warren.com.br/",
          logoPath: "warren.png",
          duration: "Jan 2021 - Fev 2023",
          location: "Porto Alegre, RS, BR · Hybrid",
          description:
            "I was responsible for addressing technical challenges caused by rapid growth. Transitioning from a monolithic to a microservices architecture to enhance scalability and autonomy, I collaborated with advanced tech and cross-functional teams, driving innovation and delivering measurable business value.",
          color: "#000000",
        },
        {
          title: "Senior Software Engineer",
          company: "Warren Investimentos",
          companyUrl: "https://warren.com.br/",
          logoPath: "warren.png",
          duration: "Nov 2019 - Dec 2020",
          location: "Porto Alegre, RS, BR · Hybrid",
          description:
            "I joined the company leading backend application development for the Wealth team. I enhanced important customer-requested features, improved deposit/withdrawal processes, designed better charts for investment data, added earnings metrics, and tax/fee info. My work greatly improved the managed portfolio experience.",
          color: "#000000",
        },
        {
          title: "Senior Software Engineer",
          company: "Hello Innovation",
          companyUrl: "https://www.helloinnovation.com/",
          logoPath: "helloInnovation.png",
          duration: "Dec 2017 - Nov 2019",
          location: "Detroit, MI, US · Remote",
          description:
            "I collaborated on a funeral services platform built with C# and .NET Framework. It included a CMS, e-commerce, and various applications. AWS tools were used, payment systems like Authorize.NET and PayPal were integrated, and Web APIs were developed for data sharing. My responsibilities covered analysis and architecture planning, development, testing, and support. The technology stack included C#, ASP.NET Web Forms and MVC, Web API, EF, ADO.NET, MVVM, HTML, CSS, JavaScript, AWS (S3, CloudFront, EC2), Sentry Error Tracking, SQL Server, Git, Azure DevOps, and more.",
          color: "#000000",
        },
        {
          title: "Senior Software Developer Analyst",
          company: "Stefanini",
          companyUrl: "https://stefanini.com/en",
          logoPath: "stefanini.png",
          duration: "May 2016 - Nov 2017",
          location: "Porto Alegre, RS, BR · On-site",
          description:
            "I redesigned a web application to help employees create detailed product/service quotes, closing deals. I used a component-based architecture for reusability and made significant changes, including database redesign and domain restructuring. My responsibilities encompassed architecture planning, development, testing, and code review using technologies like C#/ASP.NET MVC, WCF, EF, MVVM, HTML, CSS, JavaScript (jQuery and React), SQL Server, Visual Studio, TFS, and more.",
          color: "#000000",
        },
        {
          title: "Senior Software Developer Analyst",
          company: "Accera (Acquired by Neogrid)",
          companyUrl: "https://neogrid.com/",
          logoPath: "accera.png",
          duration: "Apr 2015 - May 2016",
          location: "São Leopoldo, RS, BR · On-site",
          description:
            "I played a key role in creating a web application for the Data Quality team to assess data accuracy from suppliers. My responsibilities included architecture planning, web development, testing, code reviewing, and production support. The main technologies I used for this project included C#/ASP.NET MVC, WCF, EF, MVVM, HTML, CSS, JavaScript, SQL Server, AWS (S3 and Redshift), Visual Studio and TortoiseSVN.",
          color: "#000000",
        },
        {
          title: "Software Developer Analyst",
          company: "Accera (Acquired by Neogrid)",
          companyUrl: "https://neogrid.com/",
          logoPath: "accera.png",
          duration: "Apr 2014 - Mar 2015",
          location: "São Leopoldo, RS, BR · On-site",
          description:
            "I led the transformation of an outdated SSIS data processing application into a modern cloud-native system using C# for enhanced performance and scalability. AWS tools were integrated, and a multi-layered architecture was followed. The migration was highly successful, improving integration, monitoring, and cost-efficiency. My roles included leading the effort, architecture planning, development, and production support, using technologies like C#, Windows Service, EF, AWS (SQS, S3, SES, and Redshift), SQL Server, Visual Studio and TortoiseSVN.",
          color: "#000000",
        },
        {
          title: "Software Developer",
          company: "Hewlett Packard Enterprise",
          companyUrl: "https://www.hpe.com/br/en/home.html",
          logoPath: "hpe.png",
          duration: "Aug 2013 - Mar 2014",
          location: "Porto Alegre, RS, BR · On-site",
          description:
            "I contributed to a pre-purchase financing pool management system for a major Brazilian bank, focusing on web-based platform creation to establish pools, and integration with legacy systems. I handled development, testing, mentored junior developers, and used C#/ASP.NET MVC, WCF, HTML, CSS, JavaScript and Visual Studio. Also, I led the development of an online integration system for a Brazilian insurance company, focusing on integrating insured individuals' data. It involved technical leadership, architecture planning, development, testing, code reviewing and a wide range of technologies, including C#/ASP.NET MVC, WCF, EF, MVVM, HTML, CSS, JavaScript, SignalR, SQL Server, Visual Studio, TFS and more.",
          color: "#000000",
        },
        {
          title: "Junior Software Developer",
          company: "META IT",
          companyUrl: "https://meta.com.br/",
          logoPath: "metaIt.png",
          duration: "Apr 2012 - Jul 2013",
          location: "São Leopoldo, RS, BR · On-site",
          description:
            "I worked on three internal governance systems, covering project tracking, expense management, and budget analysis. Responsibilities included analysis, planning, implementing features, bug resolution, testing, and ongoing maintenance. Technologies used: Classic ASP, MVC Design Pattern, C#/ASP.NET Web Forms, ADO.NET, NHibernate, HTML, CSS, JavaScript(jQuery), SQL Server, IIS, Visual Studio, and TortoiseSVN.",
          color: "#000000",
        },
        {
          title: "Junior Software Developer",
          company: "IOB (Acquired by Sage)",
          companyUrl: "https://www.iobonline.com.br/",
          logoPath: "iob.png",
          duration: "Oct 2011 - Apr 2012",
          location: "Porto Alegre, RS, BR · On-site",
          description:
            "I worked on an internal desktop system for the editorial department. The application assisted the entire editorial process. I handled new feature implementation, bug fixes, code testing, and code reviews. Technologies used included J2SE, MVP Design Pattern, Hibernate, SQL Server, Eclipse, and TortoiseSVN.",
          color: "#000000",
        },
        {
          title: "Software Developer Intern",
          company: "IOB (Acquired by Sage)",
          companyUrl: "https://www.iobonline.com.br/",
          logoPath: "iob.png",
          duration: "Nov 2010 - Sep 2011",
          location: "Porto Alegre, RS, BR · On-site",
          description:
            "I worked on an internal desktop system for accounting and legal purposes. The application managed official diary downloads, extracted information, and established processing workflows. I added features, fixed bugs, and ensured quality under mentorship.Technologies used included C#, SQL Server, Visual Studio, and TortoiseSVN.",
          color: "#000000",
        },
      ],
    },
    {
      title: "Talks",
      experiences: [
        {
          title: "Speaker · Vertical Slice Architecture in .NET",
          company: "TDC Innovation",
          companyUrl: "https://www.globalcode.com.br/videos/tdc-2023-innovation",
          evidence: {
            link: "https://drive.google.com/file/d/1kES-SpAtjFQyr_s3xX3k9trLz8Lyv-ov/view?usp=sharing",
            description: "Certificate"
          },
          logoPath: "tdc.png",
          duration: "Jun 2023",
          location: "Florianópolis, SC, BR · On-site",
          description:
            "I had the opportunity to present a talk about Vertical Slice Architecture in .NET at the Brazilian conference known as TDC (The Developer's Conference) Innovation. The primary purpose of this talk was to introduce this architectural concept and demonstrate how to use it to build scalable, maintainable, and adaptable to constantly changing requirements applications.",
          color: "#000000",
        },
        {
          title: "Speaker · More Quality, Less Complexity, Is It Possible?",
          company: "TDC Innovation",
          companyUrl: "https://www.globalcode.com.br/videos/tdc-2023-innovation",
          evidence: {
            link: "https://drive.google.com/file/d/1kKTsh5xpT94WwqeoUp6PqbS6AbR0NCyO/view?usp=sharing",
            description: "Certificate"
          },
          logoPath: "tdc.png",
          duration: "Jun 2023",
          location: "Florianópolis, SC, BR · On-site",
          description:
            "As a panel member, I also participated in a discussion with fellow experts on the topic \"More Quality, Less Complexity, Is It Possible?\" This insightful exchange allowed us to explore strategies for improving quality while reducing complexity in software development.",
          color: "#000000",
        },
      ]
    },
    {
      title: "Organizations",
      experiences: [
        {
          title: "Association Member",
          company: "Brazilian Computer Society (SBC)",
          companyUrl: "https://www.sbc.org.br/",
          evidence: {
            link: "https://drive.google.com/file/d/1aBEbnqjyiJaJpWtj_UvfmWa559N4Gn-Z/view?usp=sharing",
            description: "Membership Card"
          },
          logoPath: "sbc.png",
          duration: "Aug 2024 - Present",
          location: "Porto Alegre, RS, BR",
          description:
            "The Brazilian Computer Society (SBC) is a non-profit scientific society. Founded in 1978, it brings together students, faculty members, researchers, professionals, and enthusiasts in the field of computing from all over Brazil. Its mission is to promote access to information and culture through computing, encourage research and education in computing in the country, promote digital inclusion, and contribute to the development of computing professionals with social responsibility.",
          color: "#000000",
        },
        {
          title: "Association Senior Member",
          company: "Institute of Electrical and Electronics Engineers (IEEE)",
          companyUrl: "https://www.ieee.org/",
          evidence: {
            link: "https://drive.google.com/file/d/1kzPzyByrx_4ENjvUL5dwaMV2smUTUyt0/view?usp=sharing",
            description: "Membership Card"
          },
          logoPath: "ieee.png",
          duration: "Sep 2024 - Present",
          location: "New York, NY, US",
          description:
            "The IEEE (Institute of Electrical and Electronics Engineers) is the world's largest technical professional organization dedicated to advancing innovation and technological excellence for the benefit of humanity. It serves individuals across electrical, electronic, and computing fields, as well as related areas of science and technology that underlie modern civilization. IEEE inspires a global community through its highly cited publications, conferences, technology standards, and a wide range of professional and educational activities. With a core purpose of fostering technological innovation and excellence, IEEE aims to be essential to the global technical community and universally recognized for its contributions to improving global conditions.",
          color: "#000000",
        },
      ]
    },
    {
      title: "Programs",
      experiences: [
        {
          title: "Developer Program Member",
          company: "Google",
          companyUrl: "https://developers.google.com/",
          logoPath: "google.png",
          duration: "Dec 2024 - Present",
          location: "",
          description:
            "I am a member of the Google Developer Program, which provides tools, resources, and community support to developers making use of Google's platforms and APIs. The program helps foster innovation and collaboration by connecting developers with Google's cutting-edge technologies and best practices.",
          color: "#181717",
        },
        {
          title: "Education Program Member",
          company: "Github",
          companyUrl: "https://github.com/education/students",
          logoPath: "github.png",
          duration: "Sep 2024 - Present",
          location: "",
          description:
            "I am a member of the GitHub Education Program, which provides students with free access to the GitHub Student Developer Pack, a collection of tools and services for software development. I use the program to enhance my development skills and explore new technologies through hands-on experience.",
          color: "#181717",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          companyUrl: "https://docs.github.com/en/get-started/exploring-integrations/github-developer-program",
          logoPath: "github.png",
          duration: "Apr 2017 - Present",
          location: "",
          description:
            "I am a member of the GitHub Developer Program, which provides resources and support to developers building integrations with GitHub. The program helps enhance and promote tools that work with the GitHub API. I also actively contribute to many open-source projects.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    'My projects, released as libraries, help the community enhance software architecture, standardize new projects, and simplify development. These libraries enable scalable solutions, optimize data access, and support event-driven projects, with over 2 million <a href="https://www.nuget.org/profiles/fernando.lima" target="_blank" rel="noopener noreferrer">downloads</a> and usage across multiple companies and applications.',
  avatarImagePath: "projects.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Blogs.",
  avatarImagePath: "projects.svg",
};

const publications = {
  data: [
    {
      id: "the-future-of-ai-in-brazil",
      name: "The Future of AI in Brazil",
      createdAt: "2024-18-12T00:00:00Z",
      description: "Blog post published on Medium",
      url: "https://medium.com/@ffernandolima/the-future-of-ai-in-brazil-41bd9b4cc3f1",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profileImagePath: "Fernando Luiz de Lima.png",
    description:
      "I'm available on most social media platforms. Feel free to reach out, and I'll reply back as soon as possible. I'm here to assist with your software engineering, development, and architecture needs!",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I enjoy sharing some experiences from my professional journey, along with technical insights and best practices.",
    link: "https://medium.com/@ffernandolima",
    avatarImagePath: "blogsImage.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Porto Alegre, RS, BR",
    locality: "Greater Porto Alegre",
    country: "BR",
    region: "RS",
    postalCode: "",
    streetAddress: "",
    avatarImagePath: "addressImage.svg",
    locationMapLink: "https://maps.app.goo.gl/JMdDFbCdVDex8J1m6",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
